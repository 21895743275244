<template>
  <div class="content">
    <a-page-header style="padding: 0" title="About Project" />
    <Chart />
  </div>
</template>

<script>
// @ is an alias to /src
import Chart from "@/components/Chart.vue";
export default {
  components: {
    Chart,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/mixins";

.content {
  background-color: $color-white;
  padding: 15px;
  overflow: auto;
}
</style>
